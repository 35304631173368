import React, { useMemo, useState } from 'react';
import DataTable from 'react-data-table-component-footer';
import { useTranslation } from 'react-i18next';
import { dateByLanguage } from '../../../../utils/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import ModalPdf from '../../../Modal/ModalPdf/ModalPdf';
import DropdownPeriods from '../../../Select/DropdownPeriods';
import { useAuth } from '../../../../containers/UseAuth';
import { PropTypes } from 'prop-types';
import CustomSearch from '../../../CustomSearch/CustomSearch';
import UpdateInvoice from './UpdateInvoice';
import ModalOnDelete from '../../../Modal/ModalOnDelete';
import { deleteInvoiceApi } from '../../../../services/adminService';
import useDispatchNotification from '../../../../hooks/notifications/useDispatchNotification';
import useInvoiceOptions from '../../../../hooks/invoices/useInvoiceOptions';

const InvoicesTable = ({
    data,
    period,
    setPeriod,
    value,
    setValue,
    isLoading,
    setData,
    dataSaved,
    getData,
}) => {
    const { t } = useTranslation();
    const tableTranslations = 'sections.management.section.reports';
    const [isShowPdf, setIsShowPdf] = useState(false);
    const { methodOptions, invoiceTypesOptions } = useInvoiceOptions();
    const [pdfUrl, setPdfUrl] = useState('');
    const [invoice, setInvoice] = useState({});
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const auth = useAuth();
    const [typeSearch, setTypeSearch] = useState('campaigns');
    const { successNotification, warningNotification } =
        useDispatchNotification();

    const translate = (key, options) =>
        t(`sections.management.section.invoices.${key}`, options);

    const handlePdf = (pdfUrl) => {
        setIsShowPdf(true);
        setPdfUrl(pdfUrl);
    };

    const downloadFile = (pdfUrl) => {
        window.open(pdfUrl, '_blank');
    };

    const isAdmin = auth.isAdmin();
    const columns = useMemo(
        () => [
            {
                name: '',
                exportCSV: false,
                cell: (row) => (
                    <div className={`w-20 flex flex-nowrap items-center`}>
                        <span className="mr-2">
                            <FontAwesomeIcon
                                icon={['fal', 'edit']}
                                fixedWidth
                                className="text-gray-500 w-6 h-6 cursor-pointer"
                                onClick={() => {
                                    setInvoice(row);
                                    setShowModalEdit(!showModalEdit);
                                }}
                                data-for={`editInvoice${row.id}`}
                                data-tip={translate('invoices.invoice.edit')}
                            />
                            <ReactTooltip id={`editInvoice${row.id}`} />
                        </span>
                        <span className="mr-2">
                            <FontAwesomeIcon
                                icon={['fal', 'trash']}
                                fixedWidth
                                className="text-red-500 w-6 h-6 cursor-pointer"
                                onClick={() => {
                                    setInvoice(row);
                                    setShowModalDelete(!showModalDelete);
                                }}
                                data-tip={translate('invoices.invoice.delete')}
                                data-for={`deleteInvoice${row.id}`}
                            />
                            <ReactTooltip id={`deleteInvoice${row.id}`} />
                        </span>
                    </div>
                ),
                isAdmin: isAdmin,
                width: '70px',
            },
            {
                isAdmin: isAdmin,
                name: t(tableTranslations + '.users.user.id'),
                exportCSV: true,
                label: t(tableTranslations + '.users.user.id'),
                key: 'userId',
                selector: (row) => row.userId,
                sortable: true,
                width: '8%',
            },
            {
                isAdmin: isAdmin,
                name: t(tableTranslations + '.users.user.username'),
                exportCSV: true,
                label: t(tableTranslations + '.users.user.username'),
                key: 'userName',
                selector: (row) => row.userName,
                sortable: true,
            },
            {
                name: translate('invoices.invoice.date'),
                isAdmin: true,
                exportCSV: true,
                label: translate('invoices.invoice.date'),
                key: 'createdAt',
                selector: (row) => dateByLanguage(row.createdAt, 'YYMMDD'),
                sortable: true,
            },
            {
                name: translate('invoices.invoice.payment-type'),
                exportCSV: true,
                isAdmin: true,
                label: translate('invoices.invoice.payment-type'),
                key: translate('invoices.invoice.payment-type'),
                selector: (row) =>
                    methodOptions.find(
                        (method) => method.id == row.invoicePaymentMethod
                    ).label,
                sortable: true,
            },
            {
                name: translate('invoices.invoice.invoice-type'),
                exportCSV: true,
                isAdmin: true,
                label: translate('invoices.invoice.invoice-type'),
                key: translate('invoices.invoice.invoice-type'),
                selector: (row) =>
                    invoiceTypesOptions.find(
                        (method) => method.id == row.invoiceType
                    ).label,
                sortable: true,
                width: '10%',
            },
            {
                name: translate('invoices.invoice.campaign-s'),
                exportCSV: true,
                isAdmin: true,
                label: translate('invoices.invoice.campaign-s'),
                key: 'campaingName',
                cell: (row) => (
                    <div>{row.campaigns.map((id) => `#${id} `)}</div>
                ),
                sortable: true,
                width: '10%',
            },
            {
                name: translate('invoices.invoice.comment'),
                exportCSV: true,
                isAdmin: true,
                label: translate('invoices.invoice.comment'),
                key: 'campaingName',
                cell: (row) => <div>{row.invoiceComment}</div>,
                sortable: true,
                width: '15%',
            },
            {
                compact: true,
                name: translate('invoices.invoice.period'),
                exportCSV: true,
                isAdmin: true,
                label: translate('invoices.invoice.period'),
                key: 'period',
                selector: (row) => row.invoicePeriod,
                cell: (row) => {
                    let period = row.invoicePeriod;
                    if (period) {
                        period = period.split('-');
                        period =
                            dateByLanguage(period[0], 'YYMMDDHH') +
                            ' - ' +
                            dateByLanguage(period[1], 'YYMMDDHH');
                    }
                    return (
                        <div className="text-center">
                            {row.invoicePeriod ? period : ' - '}
                        </div>
                    );
                },
                sortable: true,
                width: '5%',
            },
            {
                name: 'PDF',
                right: true,
                exportCSV: true,
                isAdmin: true,
                label: 'PDF',
                key: 'invoicePdfPath',
                selector: (row) => row.invoicePdfPath,
                cell: (row) => {
                    return (
                        <div className="text-center">
                            <FontAwesomeIcon
                                icon={['fal', 'file-pdf']}
                                fixedWidth
                                className="text-blue-500 w-6 h-6 cursor-pointer"
                                onClick={() => handlePdf(row.invoicePdfPath)}
                            />
                            <ReactTooltip
                                globalEventOff="click"
                                afterShow={() => {
                                    navigator.clipboard.writeText(
                                        row.invoicePdfPath
                                    );
                                    setTimeout(() => {
                                        ReactTooltip.hide();
                                    }, 500);
                                }}
                            />
                            <FontAwesomeIcon
                                data-tip="Copied"
                                data-event="click"
                                icon={['fal', 'share-alt']}
                                fixedWidth
                                className="text-blue-500 w-6 h-6 ml-2 cursor-pointer"
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        row.invoicePdfPath
                                    );
                                }}
                            />
                            <FontAwesomeIcon
                                icon={['fal', 'download']}
                                fixedWidth
                                className="text-blue-500 w-6 h-6 ml-2 cursor-pointer"
                                onClick={() => {
                                    downloadFile(row.invoicePdfPath);
                                }}
                            />
                        </div>
                    );
                },
            },
        ],
        []
    );

    const handleSearch = (word) => {
        let wordToSearch = word.toLowerCase().trim();
        if (wordToSearch.length === 0) {
            setData(dataSaved);
            return;
        }
        const searchBy = {
            userName: () => searchByName(wordToSearch),
            campaigns: () => searchByCampaign(wordToSearch),
            invoiceComment: () => searchByName(wordToSearch),
        };
        searchBy[typeSearch]();
    };

    const searchByName = (wordToSearch) => {
        let newData = dataSaved.filter((item) => {
            let name = item[typeSearch].toLowerCase();
            return name.includes(wordToSearch);
        });
        setData(newData);
    };

    const searchByCampaign = (wordToSearch) => {
        let newData = dataSaved.filter((item) => {
            let campaigns = item.campaigns;
            let found = false;
            if (campaigns && campaigns.length > 0) {
                campaigns.forEach((campaign) => {
                    if (String(campaign).toLowerCase().includes(wordToSearch)) {
                        found = true;
                    }
                });
            }
            return found;
        });
        setData(newData);
    };

    const handleSearchType = (columnType) => {
        setTypeSearch(columnType);
    };

    const optionsSearch = [
        {
            label: translate('invoices.invoice.invoice-type-2'),
            value: 'campaigns',
            isAdmin: true,
        },
        {
            label: translate('invoices.invoice.comment'),
            value: 'invoiceComment',
            isAdmin: true,
        },
        {
            label: translate('invoices.invoice.search-by-user'),
            value: 'userName',
            isAdmin: isAdmin,
        },
    ];

    const handleConfirm = () => {
        setIsLoadingDelete(true);
        deleteInvoiceApi(invoice.id)
            .then((res) => {
                successNotification(
                    translate('invoices.invoice.delete-invoice'),
                    translate('invoices.invoice.success-deleted')
                );
                getData();
                setShowModalDelete(!showModalDelete);
            })
            .catch((err) => {
                warningNotification(
                    translate('invoices.invoice.delete-invoice'),
                    translate('invoices.invoice.warning')
                );
            })
            .finally(() => {
                setIsLoadingDelete(false);
            });
    };

    const paginationComponentOptions = {
        rangeSeparatorText: t('common.words.of'),
        rowsPerPageText: t(
            'common.components.react-data-table.screens-per-page'
        ),
        selectAllRowsItemText: t(
            'common.components.react-data-table.select-all-screens'
        ),
    };
    return (
        <>
            {showModalEdit && (
                <UpdateInvoice
                    invoice={invoice}
                    showModalEdit={showModalEdit}
                    setShowModalEdit={setShowModalEdit}
                    getData={getData}
                />
            )}
            {showModalDelete && (
                <ModalOnDelete
                    handleModal={() => setShowModalDelete(!showModalDelete)}
                    isActive={showModalDelete}
                    handleConfirm={handleConfirm}
                    handleCancel={() => setShowModalDelete(!showModalDelete)}
                    title={translate('invoices.invoice.title')}
                    body={
                        isLoadingDelete ? (
                            <div className="w-8 container mx-auto">
                                <FontAwesomeIcon
                                    icon={['fas', 'circle-notch']}
                                    className="text-blue-800 animate-spin "
                                />
                            </div>
                        ) : (
                            translate('invoices.invoice.delete-confirmation', {
                                invoiceId: invoice.id,
                            })
                        )
                    }
                />
            )}
            <span className="text-gray-600 text-xl font-light ml-3">
                {translate('invoices.invoice.title')}
            </span>
            <div className="mt-2 ml-2 flex justify-between w-full max-w-3xl">
                <div>
                    <CustomSearch
                        handleSearch={handleSearch}
                        handleSearchType={handleSearchType}
                        // options filter if not admin
                        options={optionsSearch.filter((item) => item.isAdmin)}
                        placeholder={t(
                            'sections.campaigns.new-campaign.wizard-screens.filters.search'
                        )}
                    />
                </div>
                <div className="pt-1">
                    <DropdownPeriods
                        handleDropdownPeriods={'invoicesReports'}
                        setCurrentPeriod={setPeriod}
                        currentPeriod={period}
                        value={value}
                        setValue={setValue}
                    />
                </div>
            </div>
            <DataTable
                // columns filter if not admin with prop isAdmin
                columns={columns.filter((column) => column.isAdmin)}
                data={data}
                pagination={true}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}
                exportCSV={true}
                progressPending={isLoading}
                paginationComponentOptions={paginationComponentOptions}
                noDataComponent={t(
                    'common.components.react-data-table.no-data'
                )}
                progressComponent={
                    <>
                        <FontAwesomeIcon
                            icon={['fas', 'circle-notch']}
                            className="text-blue-400 animate-spin mx-2"
                        />
                    </>
                }
            />
            <ModalPdf
                isShowPdf={isShowPdf}
                setIsShowPdf={setIsShowPdf}
                pdfUrl={pdfUrl}
            />
        </>
    );
};

InvoicesTable.propTypes = {
    data: PropTypes.array.isRequired,
    period: PropTypes.array.isRequired,
    setPeriod: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    setValue: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    setData: PropTypes.func.isRequired,
    dataSaved: PropTypes.array.isRequired,
    getData: PropTypes.func.isRequired,
};

export default InvoicesTable;
