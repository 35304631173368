import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Header from '../Header/Header';
import { useHistory, useParams, NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useService } from '../../containers/UseService';
import CreatableSelectInput from '../Input/CreatableSelectInput/CreatableSelectInput';
import CommonButton from '../Buttons/CommonButton';
import { formatDateAccordingToBrowser } from '../../utils/functions';
import { useSelector } from 'react-redux';
import Loading from '../Loading/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const NewProposal = () => {
    const { id } = useParams();
    const service = useService();
    const { t } = useTranslation();
    const history = useHistory();
    const [isActiveSection, setIsActiveSection] = useState('proposals');
    const [nameCampaign, setNameCampaign] = useState('');
    const [brand, setBrand] = useState('');
    const [createdCampaign, setCreatedCampaign] = useState('');
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const [newLabel, setNewLabel] = useState(null);
    const [optionsLabel, setOptionsLabel] = useState([]);
    const [optionsCampaigns, setOptionsCampaign] = useState([]);
    const [newCampaign, setNewCampaign] = useState(null);
    const [campaignLinks, setCampaignLinks] = useState(null);
    const [generatedProposalData, setGeneratedProposalData] = useState(null);
    const [loader, setLoader] = useState(false);
    const [newProposals, setProposals] = useState(null);
    const [loading, setLoading] = useState(false);
    const wallet = useSelector((state) => state.wallet.budgets);
    const [loadProposal, setLoadProposal] = useState(false);

    useEffect(() => {
        if (!wallet) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [wallet]);
    const [newLinked, setNewLinked] = useState(null);

    useEffect(() => {
        const fetchProposalData = async () => {
            try {
                const data = await service.getCampaignProposalById(id);
                setCreatedCampaign(data.data);
                setNameCampaign(data.data.name);
                setBrand(data.data.brand);
            } catch (err) {}
        };

        fetchProposalData();
    }, [id]);
    const getGenerateProposal = async () => {
        setLoadProposal(true);
        try {
            const response = await service.generateProposals(id);
            setProposals(response);
        } catch (error) {}
        setLoadProposal(false);
    };

    useEffect(() => {
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        const timeout = setTimeout(async () => {
            try {
                const updatedCampaign = {
                    ...createdCampaign,
                    name: nameCampaign,
                    brand: brand,
                    id: parseInt(createdCampaign.id),
                    admin_id: parseInt(createdCampaign.admin_id),
                };
                await service.updateCampaignProposal(updatedCampaign);
            } catch (err) {}
        }, 600);
        setDebounceTimeout(timeout);
        return () => clearTimeout(timeout);
    }, [nameCampaign, brand, createdCampaign]);

    useEffect(() => {
        const getLabels = async () => {
            try {
                const labelsById = await service.getLabelsByCampaignId(id);
                const refactoredLabels = labelsById.data.map((label) => ({
                    ...label,
                    label: label.name,
                    value: label.id,
                }));
                setOptionsLabel(refactoredLabels);
            } catch (error) {}
        };
        const getCampaigns = async () => {
            try {
                const campaignsByid =
                    await service.getCampaignByUserIdAndStatus(0);
                const refactorCampaigns = campaignsByid.map((campaign) => ({
                    value: campaign.id,
                    label:
                        campaign.name === null || campaign.name === ''
                            ? '#' + campaign.id + ' SIN NOMBRE'
                            : '#' + campaign.id + ' ' + campaign.name,
                }));
                setOptionsCampaign(refactorCampaigns);
            } catch {}
        };
        getLabels();
        getCampaigns();
    }, [id, newCampaign, newLabel]);

    useEffect(() => {
        const getProposals = async () => {
            try {
                const proposals = await service.usoProposal(id);
                setGeneratedProposalData(proposals.data);
            } catch {}
        };
        getProposals();
    }, [newProposals]);

    const handleCreateLabel = async (newLabel) => {
        const payload = {
            name: newLabel,
            id_campaign: parseInt(id),
        };
        try {
            const response = await service.createLabel(payload);
            const newOption = {
                label: response.data.name,
                value: response.data.id,
            };
            setNewLabel(newOption);
        } catch (error) {}
    };

    const handleCreateCampaign = async (newCampaign) => {
        try {
            const response = await service.createCampaign(newCampaign, brand);
            const newOption = {
                label: response.campaign.name,
                value: response.campaign.id,
            };
            setNewCampaign(newOption);
        } catch {}
    };

    useEffect(() => {
        if (loader === false) {
            const getLinkedCampaigns = async () => {
                try {
                    const response = await service.getLinkedCampaigns(id);
                    const newLinks = response.data.data.linkedCampaigns.map(
                        (links) => ({
                            name: `# ${links.name}`,
                            link: `/campaign/edit/${links.campaignId}`,
                            subCampaignId: links.campaignId,
                        })
                    );
                    setCampaignLinks(newLinks);
                } catch (error) {
                    setCampaignLinks([]);
                }
            };
            getLinkedCampaigns();
        }
    }, [id, newLinked, loader]);

    const linkLabelAndSubcampaign = async () => {
        if (!newLabel || !newCampaign) {
            return;
        }
        setLoader(true);
        const payload = {
            label_id: newLabel.value,
            subCampaign_id: newCampaign.value,
        };
        try {
            await service.linkLabelAndSubcampaign(payload);
            setNewLinked(payload);
        } catch (error) {}
        setLoader(false);
    };

    const unlinkSubcampaigns = async (subcampaignId) => {
        setLoader(true);
        try {
            await service.unassignLabelByCampaignId(subcampaignId);
        } catch {}
        setLoader(false);
    };

    return (
        <>
            <Helmet>
                <title>{t('common.campaign-proposals.title')}</title>
            </Helmet>
            <div className="relative pl-16 pb-10 md:pt-0 mt-12 h-screen flex content-start bg-white border-r border-gray-300 shadow-lg">
                <Header
                    currentSection={t('common.menu.dashboard')}
                    sectionPath={t('common.menu.proposals')}
                    subSection={t('common.menu.proposals')}
                    subSectionOne={t('common.campaign-proposals.create')}
                />
                {loading ? (
                    <Loading isLoading={loading}></Loading>
                ) : (
                    <div className="w-full mt-2 h-full overflow-visible text-sm">
                        <div className="flex justify-between">
                            <div>
                                <nav className="flex">
                                    <button
                                        className={`${
                                            isActiveSection === 'company'
                                                ? 'tabActive font-semibold'
                                                : ''
                                        } tabGeneral uppercase text-xs`}>
                                        {t(
                                            'common.campaign-proposals.proposal'
                                        )}
                                    </button>
                                </nav>
                            </div>
                        </div>
                        <div className="module rounded m-1 mb-4">
                            <div className="header flex justify-between content-center">
                                <div className="title text-xl font-extralight">
                                    {t(
                                        'sections.campaigns.new-campaign.wizard-basics.naming.title'
                                    )}
                                </div>
                            </div>
                            <div className="body md:flex mt-2 align-center rounded-sm p-1">
                                <div className="flex flex-col md:flex-row w-full space-y-4 md:space-y-0 md:space-x-4">
                                    <label className="block md:w-1/2">
                                        <span className="block my-3 text-sm font-medium text-gray-700">
                                            {t(
                                                'common.campaign-proposals.proposal-name'
                                            )}
                                        </span>
                                        <input
                                            value={nameCampaign}
                                            type="text"
                                            onChange={(e) =>
                                                setNameCampaign(e.target.value)
                                            }
                                            className="focus:ring-0 focus:outline-none block w-5/6 pr-12 sm:text-sm border-gray-300 rounded-md"
                                            placeholder={t(
                                                'common.campaign-proposals.proposal-name'
                                            )}
                                        />
                                    </label>
                                    <label className="block md:w-1/2">
                                        <span className="block my-3 text-sm font-medium text-gray-700">
                                            {t(
                                                'common.campaign-proposals.brand-name'
                                            )}
                                        </span>
                                        <input
                                            value={brand}
                                            type="text"
                                            onChange={(e) =>
                                                setBrand(e.target.value)
                                            }
                                            className="focus:ring-0 focus:outline-none block w-5/6 pr-12 sm:text-sm border-gray-300 rounded-md"
                                            placeholder={t(
                                                'common.campaign-proposals.brand-name'
                                            )}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                        {createdCampaign && (
                            <div className="module rounded m-1 mb-4 flex-col">
                                <div className="header flex justify-between content-center">
                                    <div className="title text-xl font-extralight">
                                        {t(
                                            'common.campaign-proposals.subcampaign-and-label'
                                        )}
                                    </div>
                                </div>
                                <div className="body w-full md:flex mt-2 rounded-sm p-1">
                                    <div className="flex flex-col md:flex-row w-full space-y-4 md:space-y-0 md:space-x-4">
                                        <label className="block md:w-1/2">
                                            <span className="block my-3 text-sm font-medium text-gray-700">
                                                {t(
                                                    'common.campaign-proposals.subcampaign-select-or-create'
                                                )}
                                            </span>
                                            <CreatableSelectInput
                                                createOptionPosition="first"
                                                options={optionsCampaigns}
                                                isClearable
                                                value={newCampaign}
                                                onChange={(selectedOption) =>
                                                    setNewCampaign(
                                                        selectedOption
                                                    )
                                                }
                                                onCreateNew={
                                                    handleCreateCampaign
                                                }
                                                placeholder={t(
                                                    'common.campaign-proposals.subcampaign-select-or-create'
                                                )}
                                            />
                                        </label>
                                        <label className="block md:w-1/2">
                                            <span className="block my-3 text-sm font-medium text-gray-700">
                                                {t(
                                                    'common.campaign-proposals.label-select-or-create'
                                                )}
                                            </span>
                                            <CreatableSelectInput
                                                createOptionPosition="first"
                                                options={optionsLabel}
                                                isClearable
                                                value={newLabel}
                                                onChange={(selectedOption) =>
                                                    setNewLabel(selectedOption)
                                                }
                                                onCreateNew={handleCreateLabel}
                                                placeholder={t(
                                                    'common.campaign-proposals.label-select-or-create'
                                                )}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="mt-4 flex mr-5 justify-end">
                                    <CommonButton
                                        disabled={
                                            newLabel && newCampaign
                                                ? false
                                                : true
                                        }
                                        useLoader={true}
                                        type="primaryButton"
                                        onClick={linkLabelAndSubcampaign}
                                        label={t(
                                            'common.campaign-proposals.link-subcampaign-and-label'
                                        )}
                                        styleType={'primary'}
                                        className={'padding-5'}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="module rounded-lg p-4 shadow-md bg-white m-2">
                            <div className="header flex justify-between items-center mb-4">
                                <div className="title text-xl font-extralight">
                                    {t(
                                        'common.campaign-proposals.linked-subcampaigns'
                                    )}
                                </div>
                            </div>

                            {campaignLinks && campaignLinks.length > 0 ? (
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                    {campaignLinks.map((linkItem, index) => (
                                        <div
                                            key={index}
                                            className="card shadow-lg rounded-lg p-6 bg-white border border-gray-200 hover:shadow-xl transition-shadow duration-300 ease-in-out">
                                            <div className="flex justify-between items-center">
                                                <button
                                                    className="font-bold text-blue-600 hover:text-blue-800 px-5 py-2 rounded-md transition duration-200"
                                                    onClick={() =>
                                                        history.push(
                                                            linkItem.link
                                                        )
                                                    }>
                                                    {linkItem.name}
                                                </button>
                                                <button
                                                    className="text-red-400 hover:text-red-600 transition-all duration-200"
                                                    onClick={() =>
                                                        unlinkSubcampaigns(
                                                            linkItem.subCampaignId
                                                        )
                                                    }>
                                                    <FontAwesomeIcon
                                                        icon={['fa', 'trash']}
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div
                                    className="w-full flex font-light align-center justify-center"
                                    style={{ minWidth: '100%' }}>
                                    {t(
                                        'common.campaign-proposals.no-subcampaign-linked'
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="module rounded m-1 mb-4 flex-col">
                            <div className="flex flex-col items-center">
                                <CommonButton
                                    disabled={
                                        campaignLinks && campaignLinks.length
                                            ? false
                                            : true
                                    }
                                    useLoader={true}
                                    type="primaryButton"
                                    onClick={getGenerateProposal}
                                    label={t(
                                        'common.campaign-proposals.generate-proposal'
                                    )}
                                    styleType={'primary'}
                                />
                                {loadProposal ? (
                                    <div className="mt-2 text-gray-500">
                                        {t(
                                            'common.campaign-proposals.pending-proposal'
                                        )}
                                    </div>
                                ) : null}
                            </div>

                            <div className="header flex justify-between content-center mt-4">
                                <div className="title text-xl font-extralight">
                                    {t(
                                        'common.campaign-proposals.generated-proposals'
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className={`module rounded m-1 mb-4 flex-col`}>
                            {' '}
                            {generatedProposalData ? (
                                <div className="font-light w-full flex flex-col items-center">
                                    {' '}
                                    <span className="mb-100">
                                        {t(
                                            'common.campaign-proposals.proposals-briefs'
                                        )}
                                    </span>
                                    {generatedProposalData.map(
                                        (proposal, index) => (
                                            <div
                                                className="w-1/2 flex justify-evenly mt-5 align-center"
                                                key={proposal.id}>
                                                <p className="font-normal text-gray-500">
                                                    <strong className="mr-2">
                                                        v
                                                        {generatedProposalData.length -
                                                            index}
                                                    </strong>

                                                    {formatDateAccordingToBrowser(
                                                        proposal.created_at
                                                    )}
                                                </p>
                                                <NavLink
                                                    to={`/proposal/brief/${proposal.id}`}
                                                    className="text-sm text-blue-500">
                                                    {t(
                                                        'common.campaign-proposals.see-brief'
                                                    )}
                                                </NavLink>
                                            </div>
                                        )
                                    )}
                                </div>
                            ) : (
                                <div
                                    className="w-full flex font-light align-center justify-center"
                                    style={{ minWidth: '100%' }}>
                                    {t(
                                        'common.campaign-proposals.without-brief'
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
