import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserSelector from '../../../Input/UserSelector/UserSelector';
import Select from 'react-select';
import InputWithLabel from '../../../Input/WithLabel/InputWithLabel';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/DateRangePicker';
import moment from 'moment';
import { getCampaignsByUserID } from '../../../../services/adminService';
import CreateButton from '../../../Buttons/CreateButton';
import useInvoiceOptions from '../../../../hooks/invoices/useInvoiceOptions';
import Dropzone from '../../../../components/Input/Dropzone/Dropzone';
import { PropTypes } from 'prop-types';
import useDispatchNotification from '../../../../hooks/notifications/useDispatchNotification';
import './DataRangerPicker.css';

const InvoiceForm = ({
    onSubmit,
    isLoading,
    setIsLoading,
    isSuccess,
    setIsSuccess,
    setFormInvoice,
    formInvoice,
    inputFileRef,
}) => {
    const {
        invoiceTypesOptions,
        methodOptions,
        initialInvoice,
        initialPeriod,
    } = useInvoiceOptions();
    const [campaignsOptions, setCampaignsOptions] = useState([]);
    const { successNotification, warningNotification, errorNotification } =
        useDispatchNotification();
    const { t } = useTranslation();
    const translate = (key) => t(`sections.management.section.invoices.${key}`);
    const [isEdit, setIsEdit] = useState(null);
    const handlePaymentMethod = (paymentMethod) => {
        setFormInvoice({
            ...formInvoice,
            paymentMethod: paymentMethod,
        });
    };

    const resetForm = () => {
        setFormInvoice(initialInvoice);
        if (inputFileRef?.current) inputFileRef.current.value = '';
    };
    const handleInvoiceType = (invoiceType) => {
        const newInvoice = {
            ...formInvoice,
            invoiceType: invoiceType,
        };
        if (invoiceType.id === 1) {
            newInvoice.campaigns = initialInvoice.campaigns;
            newInvoice.campaignsLabel = initialInvoice.campaignsLabel;
        } else {
            newInvoice.invoicePeriod = initialInvoice.invoicePeriod;
            newInvoice.invoicePeriodLabel = initialInvoice.invoicePeriodLabel;
        }
        setFormInvoice(newInvoice);
        // campaigns
    };

    const handlePeriod = (periodValue) => {
        if (periodValue) {
            const label = [
                moment(periodValue[0]).format('YYYY-MM-DDTHH:mm:ss.SSS'),
                moment(periodValue[1]).format('YYYY-MM-DDTHH:mm:ss.SSS'),
            ];
            const value = `${moment(periodValue[0]).format(
                'YYMMDDHH'
            )}-${moment(periodValue[1]).format('YYMMDDHH')}`;

            setFormInvoice({
                ...formInvoice,
                invoicePeriod: value,
                invoicePeriodLabel: label,
            });
        }
    };

    const handleChangeCampaigns = (selectedCampaigns) => {
        setFormInvoice({
            ...formInvoice,
            campaigns: selectedCampaigns.map((campaign) => campaign.value),
            campaignsLabel: selectedCampaigns,
        });
    };

    const handleSelectedUser = (selectedUser) => {
        setFormInvoice({
            ...formInvoice,
            userName: selectedUser.username,
            userId: selectedUser.id,
            userLabel: selectedUser,
        });
    };

    const handleChangeFile = (e) => {
        const isEventDrop = e.dataTransfer?.files;
        const file = isEventDrop ? e.dataTransfer.files[0] : e.target.files[0];
        if (file && file.type !== 'application/pdf') {
            e.target.value = [];
            warningNotification(translate('invoices.invoice.pdf-upload-error'));
            return;
        }
        if (file.size > 513000) {
            e.target.value = [];
            warningNotification(
                translate('invoices.invoice.new-invoice'),
                translate('invoices.invoice.max-size')
            );
            return;
        }
        setFormInvoice({
            ...formInvoice,
            filePDFUrl: URL.createObjectURL(file),
            file: file,
            invoicePdfName: file.name,
        });
    };

    const validations = () => {
        if (
            formInvoice.userId &&
            formInvoice.paymentMethod &&
            formInvoice.invoiceType &&
            formInvoice.filePDFUrl
        ) {
            if (formInvoice.invoiceType.value === 'period') {
                if (formInvoice.invoicePeriod) {
                    return true;
                }
            } else if (formInvoice.invoiceType.value === 'campaigns') {
                if (formInvoice.campaigns.length > 0) {
                    return true;
                }
            }
        }
        return false;
    };

    const handleSubmit = () => {
        const isValid = validations();
        if (isValid) {
            onSubmit()
                .then((res) => {
                    if (!res.error) resetForm();
                })
                .finally(() => {
                    setIsLoading(false);
                    setTimeout(() => {
                        setIsSuccess(false);
                    }, 1000);
                });
        } else {
            warningNotification(
                translate('invoices.invoice.error-create'),
                translate('invoices.invoice.empty-fields')
            );
        }
    };

    useEffect(() => {
        if (formInvoice.userId) {
            getCampaignsByUserID(formInvoice.userId)
                .then((res) => {
                    const campaigns = res.data.map((campaign) => {
                        return {
                            value: campaign.id,
                            label: `#${campaign.id} - ${campaign.name}`,
                            name: campaign.name,
                        };
                    });
                    setCampaignsOptions(campaigns.reverse());
                    setIsEdit(true);
                })
                .catch((err) => {
                    console.log('err: ', err);
                });
        }
    }, [formInvoice.userId]);

    return (
        <>
            <span className="text-gray-600 text-xl font-light">
                {isEdit
                    ? translate('invoices.invoice.edit')
                    : translate('invoices.invoice.new-invoice')}
            </span>
            <div
                className={`w-full max-w-5xl bg-white bg-opacity-90 pt-4 pl-0 grid grid-cols-1 sm:grid-cols-2 `}>
                <InputWithLabel
                    title={translate('invoices.invoice.username')}
                    label={translate('invoices.invoice.user-placeholder')}
                    icon="user"
                    idHtmlFor="userName">
                    <UserSelector
                        idCustom="userName"
                        value={formInvoice.userLabel}
                        onChange={handleSelectedUser}
                    />
                </InputWithLabel>
                <InputWithLabel
                    title={translate('invoices.invoice.payment-type')}
                    label={translate(
                        'invoices.invoice.payment-type-placeholder'
                    )}
                    icon="money-bill"
                    idHtmlFor="paymentMethod">
                    <Select
                        inputId="paymentMethod"
                        options={methodOptions}
                        // unica forma de resetear el valor
                        value={
                            formInvoice.paymentMethod
                                ? formInvoice.paymentMethod
                                : null
                        }
                        onChange={handlePaymentMethod}
                        placeholder={translate('invoices.invoice.payment-type')}
                    />
                </InputWithLabel>
                <InputWithLabel
                    title={translate('invoices.invoice.invoice-type')}
                    label={translate(
                        'invoices.invoice.invoice-type-placeholder'
                    )}
                    icon="file-invoice-dollar"
                    idHtmlFor="invoiceType">
                    <Select
                        inputId="invoiceType"
                        options={invoiceTypesOptions}
                        value={
                            formInvoice.invoiceType?.id
                                ? formInvoice.invoiceType
                                : invoiceTypesOptions[1]
                        }
                        onChange={handleInvoiceType}
                        placeholder={translate('invoices.invoice.invoice-type')}
                    />
                </InputWithLabel>
                {formInvoice.invoiceType.value === 'period' ? (
                    <InputWithLabel
                        title={translate('invoices.invoice.period')}
                        label={translate('invoices.invoice.period-placeholder')}
                        icon="calendar-alt"
                        idHtmlFor="period">
                        <DateRangePicker
                            clearIcon={null}
                            rangeDivider={t(
                                'common.components.DateRangePicker.until'
                            )}
                            onChange={(periodValue) =>
                                handlePeriod(periodValue)
                            }
                            value={
                                formInvoice.invoicePeriod
                                    ? formInvoice.invoicePeriodLabel
                                    : initialPeriod
                            }
                            // con ancho adaptable
                            style={{ width: '100%' }}
                            calendarClassName="absolute top-10 "
                            className="w-full"
                        />
                    </InputWithLabel>
                ) : (
                    <InputWithLabel
                        title={translate('invoices.invoice.campaigns')}
                        label={translate(
                            'invoices.invoice.campaigns-placeholder'
                        )}
                        icon="list-ul"
                        idHtmlFor="campaigns">
                        <Select
                            inputId="campaigns"
                            isMulti={true}
                            options={campaignsOptions}
                            multiple={true}
                            value={formInvoice.campaignsLabel}
                            onChange={handleChangeCampaigns}
                            placeholder={translate(
                                'invoices.invoice.campaign-s'
                            )}
                            className="w-full"
                        />
                    </InputWithLabel>
                )}
                <InputWithLabel
                    title={translate('invoices.invoice.comment')}
                    label={translate('invoices.invoice.comment-placeholder')}
                    icon="comment"
                    idHtmlFor="comment"
                    className="col-span-1 md:col-span-2">
                    <textarea
                        id="comment"
                        maxLength={255}
                        value={formInvoice.comment || ''}
                        className="w-full h-auto rounded-md border-1 border-gray-300"
                        placeholder={translate('invoices.invoice.comment')}
                        onChange={(e) =>
                            setFormInvoice({
                                ...formInvoice,
                                comment: e.target.value,
                            })
                        }
                    />
                </InputWithLabel>
                <InputWithLabel
                    title={translate('invoices.invoice.pdf')}
                    label={
                        translate('invoices.invoice.pdf-upload-placeholder') +
                        ' (' +
                        translate('invoices.invoice.max-size') +
                        ')'
                    }
                    icon="file-pdf"
                    idHtmlFor="file-to-upload"
                    className="col-span-1 md:col-span-2 mb-4 ">
                    <Dropzone
                        loading={isLoading}
                        inputFileRef={inputFileRef}
                        onDrop={handleChangeFile}
                        disabled={false}>
                        <input
                            type="file"
                            id="file-to-upload"
                            accept=".pdf"
                            ref={inputFileRef}
                            onChange={handleChangeFile}
                            style={{ display: 'none' }}
                        />
                    </Dropzone>
                    {formInvoice.filePDFUrl && (
                        <iframe
                            className="mt-2 w-4/6 h-4/6 pb-8 mx-auto"
                            src={formInvoice.filePDFUrl}
                            title="pdf"
                        />
                    )}
                </InputWithLabel>
            </div>
            <div className="flex w-full max-w-5xl justify-end gap-4 px-4 pr-5 mt-10">
                <button
                    className="capitalize bg-gray-300 text-black rounded transition-colors duration-300 ease-in-out font-light text-sm p-1 py-2 px-2 lg:px-4 focus:outline-none mt-4"
                    onClick={() => {
                        resetForm();
                    }}>
                    {translate('invoices.invoice.reset')}
                </button>
                <CreateButton
                    handleClick={() => {
                        handleSubmit();
                    }}
                    isLoading={isLoading}
                    isSuccess={isSuccess}
                    // isError={isValid}
                    label={translate('invoices.invoice.save')}
                />
            </div>
        </>
    );
};

InvoiceForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    setIsLoading: PropTypes.func.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    setIsSuccess: PropTypes.func.isRequired,
    setFormInvoice: PropTypes.func.isRequired,
    formInvoice: PropTypes.object.isRequired,
    inputFileRef: PropTypes.object.isRequired,
};

export default InvoiceForm;
