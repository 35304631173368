import Modal from '../../../Modal/Modal';
import ModalHeader from '../../../Modal/ModalHeader/ModalHeader';
import ModalBody from '../../../Modal/ModalBody/ModalBody';
import moment from 'moment';
import useInvoiceOptions from '../../../../hooks/invoices/useInvoiceOptions';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InvoiceForm from '../Form/InvoiceForm';
import { PropTypes } from 'prop-types';
import { updateInvoiceApi } from '../../../../services/adminService';
import useDispatchNotification from '../../../../hooks/notifications/useDispatchNotification';

const UpdateInvoice = ({
    setShowModalEdit,
    showModalEdit,
    invoice,
    getData,
}) => {
    const { invoiceTypesOptions, methodOptions } = useInvoiceOptions();
    const { successNotification, warningNotification } =
        useDispatchNotification();
    const campaignsLabel = invoice.campaignsData.map((campaign) => {
        return {
            value: campaign.id,
            label: `${campaign.id} - ${campaign.name}`,
            name: campaign.name,
        };
    });

    const invoiceType = invoiceTypesOptions.find(
        (type) => type.id === Number(invoice.invoiceType)
    );
    const paymentMethod = methodOptions.find(
        (method) => method.id === Number(invoice.invoicePaymentMethod)
    );

    const invoicePeriodLabel = () => {
        if (invoice.invoiceType == 2) return [null, null];
        else {
            return [
                moment(invoice.invoicePeriod.split('-')[0], 'YYMMDDHH').format(
                    'YYYY-MM-DDTHH:mm:ss.SSS'
                ),
                moment(invoice.invoicePeriod.split('-')[1], 'YYMMDDHH').format(
                    'YYYY-MM-DDTHH:mm:ss.SSS'
                ),
            ];
        }
    };

    const invoiceToEdit = {
        id: invoice.id,
        comment: invoice.invoiceComment,
        invoicePdfName: invoice.pdfName,
        filePDFUrl: invoice.invoicePdfPath,
        campaigns: invoice.campaignIds,
        invoicePeriod: invoice.invoicePeriod,
        invoicePeriodLabel: invoicePeriodLabel(),
        campaignsLabel,
        userLabel: {
            id: Number(invoice.userId),
            username: invoice.userName,
            label: invoice.userName,
            value: Number(invoice.userId),
        },
        invoiceType,
        paymentMethod,
        userName: invoice.userName,
        userId: invoice.userId,
    };

    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [formInvoice, setFormInvoice] = useState(invoiceToEdit);
    const inputFileRef = useRef();
    const { t } = useTranslation();
    const translate = (key) => t(`sections.management.section.invoices.${key}`);

    const updateInvoice = async () => {
        setIsLoading(true);
        const response = await updateInvoiceApi(formInvoice);
        setIsSuccess(true);
        if (response.error) {
            warningNotification(translate('invoices.invoice.error-create'));
            return { error: true };
        } else {
            getData();
            setShowModalEdit(false);
            successNotification(translate('invoices.invoice.success-create'));
            return { error: false };
        }
    };

    return (
        <Modal
            size="lg"
            active={showModalEdit}
            toggler={() => setShowModalEdit(!showModalEdit)}>
            <ModalHeader
                toggler={() => setShowModalEdit(!showModalEdit)}></ModalHeader>
            <ModalBody>
                <InvoiceForm
                    onSubmit={updateInvoice}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    isSuccess={isSuccess}
                    setIsSuccess={setIsSuccess}
                    setFormInvoice={setFormInvoice}
                    formInvoice={formInvoice}
                    inputFileRef={inputFileRef}
                />
            </ModalBody>
        </Modal>
    );
};

UpdateInvoice.propTypes = {
    getData: PropTypes.func.isRequired,
    showModalEdit: PropTypes.bool.isRequired,
    setShowModalEdit: PropTypes.func.isRequired,
    invoice: PropTypes.object.isRequired,
};

export default UpdateInvoice;
